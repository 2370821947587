import React, { FC } from 'react';

import { iconColors } from '../../themes/oxxio/tokens';
import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const TerracedHouse: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      d="M74.88 24.36L65.78 11.67C65.03 10.63 63.82 10 62.53 10C61.25 10 60.04 10.62 59.28 11.66L50.13 24.35C49.39 25.38 49 26.59 49 27.86V68C49 69.1 49.9 70 51 70H72C74.21 70 76 68.21 76 66V27.86C76 26.6 75.61 25.39 74.88 24.36ZM53 66V27.86C53 27.44 53.13 27.03 53.38 26.69L62.53 13.99L71.63 26.68C71.87 27.02 72 27.42 72 27.85V65.99H53V66ZM20.78 11.67C20.03 10.63 18.82 10 17.53 10C16.25 10 15.04 10.62 14.28 11.66L5.13 24.36C4.39 25.39 4 26.6 4 27.87V66.01C4 68.22 5.79 70.01 8 70.01H29C30.1 70.01 31 69.11 31 68.01V27.86C31 26.6 30.61 25.39 29.88 24.36L20.78 11.67ZM27 66H8V27.86C8 27.44 8.13 27.03 8.38 26.69L17.53 13.99L26.63 26.68C26.87 27.02 27 27.42 27 27.85V65.99V66Z"
      fill={color ?? iconColors.iconSecondary}
    />
    <path d="M49 70H31C28.79 70 27 68.21 27 66V27.86C27 26.61 27.37 25.41 28.07 24.39L36.82 11.69C37.54 10.64 38.74 10.01 40.02 10.01C41.3 10.01 42.5 10.64 43.22 11.7L51.92 24.39C52.62 25.41 52.99 26.61 52.99 27.85V65.99C52.99 68.2 51.2 69.99 48.99 69.99L49 70ZM40.02 14.1L31.36 26.66C31.12 27 30.99 27.43 30.99 27.86V66H48.99V27.86C48.99 27.43 48.86 27.01 48.62 26.66L40.01 14.1H40.02Z" />
  </Illustration>
);
