import React, { FC } from 'react';

import { iconColors } from '../../themes/oxxio/tokens';
import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const HeatpumpSide: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      d="M76 45V47C76 48.1 75.1 49 74 49C72.9 49 72 48.1 72 47C70.9 47 70 46.1 70 45C70 43.9 70.9 43 72 43H74C75.1 43 76 43.9 76 45ZM65 43H61C59.9 43 59 43.9 59 45C59 46.1 59.9 47 61 47H65C66.1 47 67 46.1 67 45C67 43.9 66.1 43 65 43ZM74 52C72.9 52 72 52.9 72 54V63C72 64.1 72.9 65 74 65C75.1 65 76 64.1 76 63V54C76 52.9 75.1 52 74 52ZM56 45C56 46.1 55.1 47 54 47V66.92C54 70.27 51.26 73 47.9 73H16.11C12.74 73 10.01 70.27 10.01 66.92V34.88C10.01 33.44 10.52 32.05 11.45 30.95L27.39 12.15C28.55 10.78 30.25 9.98999 32.05 9.98999C33.85 9.98999 35.55 10.78 36.71 12.16L52.56 30.95C53.48 32.05 53.99 33.44 53.99 34.87V43C55.09 43 55.99 43.9 55.99 45H56ZM50 63V34.87C50 34.38 49.83 33.91 49.51 33.53L33.66 14.74C33.25 14.26 32.68 13.99 32.05 13.99C31.42 13.99 30.85 14.25 30.44 14.73L14.5 33.53C14.18 33.91 14.01 34.38 14.01 34.87V66.91C14.01 68.06 14.95 68.99 16.11 68.99H47.9C49.06 68.99 50 68.06 50 66.91V62.99V63Z"
      fill={color ?? iconColors.iconSecondary}
    />
    <path d="M70 73H56C52.69 73 50 70.31 50 67V62C50 58.69 52.69 56 56 56H70C73.31 56 76 58.69 76 62V67C76 70.31 73.31 73 70 73ZM56 60C54.9 60 54 60.9 54 62V67C54 68.1 54.9 69 56 69H70C71.1 69 72 68.1 72 67V62C72 60.9 71.1 60 70 60H56Z" />
  </Illustration>
);
