import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const SmartMeter: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M76 36V60C76 65.51 71.51 70 66 70H14C8.49 70 4 65.51 4 60V36C4 30.49 8.49 26 14 26C15.1 26 16 26.9 16 28C16 29.1 15.1 30 14 30C10.69 30 8 32.69 8 36V60C8 63.31 10.69 66 14 66H66C69.31 66 72 63.31 72 60V36C72 32.69 69.31 30 66 30C64.9 30 64 29.1 64 28C64 26.9 64.9 26 66 26C71.51 26 76 30.49 76 36ZM55 51C55 47.69 57.69 45 61 45C64.31 45 67 47.69 67 51C67 54.31 64.31 57 61 57C57.69 57 55 54.31 55 51ZM59 51C59 52.1 59.9 53 61 53C62.1 53 63 52.1 63 51C63 49.9 62.1 49 61 49C59.9 49 59 49.9 59 51ZM18 45H45C48.31 45 51 47.69 51 51C51 54.31 48.31 57 45 57H18C14.69 57 12 54.31 12 51C12 47.69 14.69 45 18 45ZM45 49H18C16.9 49 16 49.9 16 51C16 52.1 16.9 53 18 53H45C46.1 53 47 52.1 47 51C47 49.9 46.1 49 45 49ZM17.37 17.37C29.85 4.88996 50.15 4.88996 62.63 17.37C63.02 17.76 63.53 17.96 64.04 17.96C64.55 17.96 65.06 17.76 65.45 17.37C66.23 16.59 66.23 15.32 65.45 14.54C51.42 0.509956 28.58 0.509956 14.54 14.54C13.76 15.32 13.76 16.59 14.54 17.37C15.32 18.15 16.59 18.15 17.37 17.37ZM56.97 25.03C57.48 25.03 57.99 24.83 58.38 24.44C59.16 23.66 59.16 22.39 58.38 21.61C48.24 11.47 31.75 11.47 21.61 21.61C20.83 22.39 20.83 23.66 21.61 24.44C22.39 25.22 23.66 25.22 24.44 24.44C33.02 15.86 46.98 15.86 55.55 24.44C55.94 24.83 56.45 25.03 56.96 25.03H56.97ZM51.31 28.69C48.29 25.67 44.27 24 40 24C35.73 24 31.71 25.66 28.69 28.69C27.91 29.47 27.91 30.74 28.69 31.52C29.47 32.3 30.74 32.3 31.52 31.52C33.79 29.25 36.8 28.01 40.01 28.01C43.22 28.01 46.23 29.26 48.5 31.52C48.89 31.91 49.4 32.11 49.91 32.11C50.42 32.11 50.93 31.91 51.32 31.52C52.1 30.74 52.1 29.47 51.32 28.69H51.31ZM43 37C43 35.34 41.66 34 40 34C38.34 34 37 35.34 37 37C37 38.66 38.34 40 40 40C41.66 40 43 38.66 43 37Z"
    />
  </Illustration>
);
