import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const ElectricalOutlet: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63 7H17C11.49 7 7 11.49 7 17V63C7 68.51 11.49 73 17 73H63C68.51 73 73 68.51 73 63V17C73 11.49 68.51 7 63 7ZM69 63C69 66.31 66.31 69 63 69H17C13.69 69 11 66.31 11 63V17C11 13.69 13.69 11 17 11H63C66.31 11 69 13.69 69 17V63ZM40 17C27.3 17 17 27.3 17 40C17 52.7 27.3 63 40 63C52.7 63 63 52.7 63 40C63 27.3 52.7 17 40 17ZM42 58.89V51C42 49.9 41.1 49 40 49C38.9 49 38 49.9 38 51V58.89C28.46 57.89 21 49.8 21 40C21 30.2 28.46 22.11 38 21.11V28C38 29.1 38.9 30 40 30C41.1 30 42 29.1 42 28V21.11C51.54 22.11 59 30.2 59 40C59 49.8 51.54 57.89 42 58.89ZM34 40C34 42.21 32.21 44 30 44C27.79 44 26 42.21 26 40C26 37.79 27.79 36 30 36C32.21 36 34 37.79 34 40ZM54 40C54 42.21 52.21 44 50 44C47.79 44 46 42.21 46 40C46 37.79 47.79 36 50 36C52.21 36 54 37.79 54 40Z"
    />
  </Illustration>
);
