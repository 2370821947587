import React, { FC } from 'react';

import { iconColors } from '../../themes/oxxio/tokens';
import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const CornerHouse: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      d="M74.88 24.36L65.78 11.67C65.03 10.63 63.82 10 62.53 10C61.25 10 60.03 10.62 59.28 11.66L51.04 23.09L43.23 11.7C42.51 10.64 41.31 10.01 40.03 10.01C38.75 10.01 37.56 10.64 36.83 11.69L28.08 24.38C27.38 25.4 27 26.6 27 27.85V67.99C27 69.09 27.9 69.99 29 69.99H72C74.21 69.99 76 68.2 76 65.99V27.86C76 26.6 75.61 25.39 74.88 24.36ZM31 27.86C31 27.43 31.13 27 31.37 26.66L40.03 14.1L48.64 26.66C48.88 27 49.01 27.43 49.01 27.86V66H31V27.86ZM53 66V27.86C53 27.44 53.13 27.03 53.38 26.69L62.53 13.99L71.63 26.68C71.88 27.02 72 27.42 72 27.85V65.99H53V66Z"
      fill={color ?? iconColors.iconSecondary}
    />
    <path d="M29 70H8C5.79 70 4 68.21 4 66V27.86C4 26.59 4.39 25.38 5.13 24.35L14.28 11.66C15.03 10.62 16.24 10 17.53 10C18.81 10 20.03 10.63 20.78 11.67L29.88 24.36C30.61 25.38 31 26.59 31 27.86V68C31 69.1 30.1 70 29 70ZM17.53 14L8.38 26.7C8.13 27.04 8 27.45 8 27.87V66.01H27V27.86C27 27.44 26.87 27.04 26.63 26.69L17.53 14Z" />
  </Illustration>
);
